import "./Display.scss";
import experienceHelios from "../../experienceHelios.json";
import experienceFreelance from "../../experienceFreelance.json";
import experienceKobo from "../../experienceKobo.json";

function Display({ index, current, type, title, background, openModal }) {
  const backImg = "images/prevImage/" + background;
  let currentProject;
  switch (current) {
    case "helios":
      currentProject = experienceHelios[index];
      break;
    case "freelance":
      currentProject = experienceFreelance[index];
      break;
    case "kobo":
      currentProject = experienceKobo[index];
      break;
    default:
      break;
  }

  if (type === "row") {
    return (
      <div
        className="RowContainer"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        tabIndex="0"
      >
        <div className="RowInfo">{title}</div>
        <div onClick={(event) => openModal(index)} className="HoverOverlay">
          <div className="RowHoverBubble">
            <div className="overlayInfo">
              <p>{currentProject.type}</p>
              <p>{currentProject.tools}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "column") {
    return (
      <div
        className="ColumnContainer"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        tabIndex="0"
      >
        <div className="ColumnInfo">{title}</div>
        <div onClick={(event) => openModal(index)} className="HoverOverlay">
          <div className="ColumnHoverBubble">
            <div className="overlayInfo">
              <p>{currentProject.type}</p>
              <p>{currentProject.tools}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "half") {
    return (
      <div
        className="HalfContainer"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        tabIndex="0"
      >
        <div className="HalfInfo">{title}</div>
        <div onClick={(event) => openModal(index)} className="HoverOverlay">
          <div className="HalfHoverBubble">
            <div className="overlayInfo">
              <p>{currentProject.type}</p>
              <p>{currentProject.tools}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Display;
