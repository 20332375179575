import "./Footer.scss";

function Footer() {
  return (
    <footer>
      {" "}
      <small>&copy; Copyright 2024, Sara Artese</small>{" "}
    </footer>
  );
}

export default Footer;
