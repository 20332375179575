import "./ModalDisplay.scss";
import experienceHelios from "../../experienceHelios.json";
import experienceFreelance from "../../experienceFreelance.json";
import experienceKobo from "../../experienceKobo.json";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function ModalDisplay({ projectIndex, currentExp }) {
  // const currentProject = experienceHelios[projectIndex];
  let currentProject;
  switch (currentExp) {
    case "helios":
      currentProject = experienceHelios[projectIndex];
      break;
    case "freelance":
      currentProject = experienceFreelance[projectIndex];
      break;
    case "kobo":
      currentProject = experienceKobo[projectIndex];
      break;
    default:
      break;
  }

  const mainPhoto = "images/prevImage/" + currentExp + currentProject.mainimage;

  const images = currentProject.gallery.map((value) => {
    return { original: "images/Media/" + value };
  });

  return (
    <div className="ModalInner">
      <div className="bubbleFive"></div>
      <div className="bubbleOne"></div>
      <h1 className="title">{currentProject.project}</h1>
      <h2 className="type">{currentProject.type}</h2>
      <div className="mainDetails">
        <div className="mainImage">
          <img
            src={`${mainPhoto}`}
            alt="Photo of current project"
            className="photo"
          />
        </div>
        <div className="role">
          <p className="roleTitle "> Role: </p>
          <p className="roleList "> {currentProject.role} </p>
        </div>
        <div className="tools">
          <p className="toolTitle "> Tools: </p>
          <p className="toolList "> {currentProject.tools} </p>
        </div>
      </div>
      <div className="bubbleTwo"></div>
      <div className="bubbleFour"></div>
      <p className="description"> {currentProject.description}</p>
      {currentProject.websiteLink !== "" && (
        <div className="link">
          <a href={currentProject.websiteLink} target="_blank">
            Website
          </a>
        </div>
      )}
      <div className="galleryContainer">
        <div className="gallery">
          <ImageGallery
            items={images}
            lazyLoad={true}
            showThumbnails={true}
            showPlayButton={false}
          />
        </div>
      </div>
      <div className="bubbleThree"></div>
    </div>
  );
}

export default ModalDisplay;
