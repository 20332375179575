import "./Experience.scss";
import experienceHelios from "../../experienceHelios.json";
import experienceFreelance from "../../experienceFreelance.json";
import experienceKoboJson from "../../experienceKobo.json";
import Display from "./Display";
import ModalDisplay from "./ModalDisplay";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useMemo } from "react";

Modal.setAppElement(document.getElementById("root"));

function Experience() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [currentExperience, setCurrentExperience] = useState();

  function openModal(e, current) {
    setSelectedProject(e);
    setCurrentExperience(current);
    setIsOpen(true);
    console.log(current);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="ExperienceContainer">
      {/* Freelance */}
      <div className="ExperienceTitle"></div>
      <h1 className="jobTitle"> Freelance Clients</h1>
      <div className="ExperienceGrid">
        {experienceFreelance.map((data, index) => (
          <Display
            current="freelance"
            title={data.project}
            background={data.background}
            type={data.display}
            openModal={(e) => openModal(e, "freelance")}
            index={index}
            key={index}
          ></Display>
        ))}
      </div>

      {/* Kobo */}
      <div className="ExperienceTitle"></div>
      <h1 className="jobTitle"> Kobo Inc - Front-End Team</h1>
      <div className="ExperienceGrid">
        <h2> Coming Soon</h2>
        {/* {experienceKoboJson.map((data, index) => (
          <Display
            current="kobo"
            title={data.project}
            background={data.background}
            type={data.display}
            openModal={(e) => openModal(e, "kobo")}
            index={index}
            key={index}
          ></Display>
        ))} */}
      </div>

      {/* Helios */}
      <div className="ExperienceTitle"></div>
      <h1 className="jobTitle"> Helios Interactive - Web Team</h1>
      <div className="ExperienceGrid">
        {experienceHelios.map((data, index) => (
          <Display
            current="helios"
            title={data.project}
            background={data.background}
            type={data.display}
            openModal={(e) => openModal(e, "helios")}
            index={index}
            key={index}
          ></Display>
        ))}
      </div>
      {/* MODAL */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="CloseBtn">
          X
        </button>
        <ModalDisplay
          projectIndex={selectedProject}
          currentExp={currentExperience}
        ></ModalDisplay>
      </Modal>
    </div>
  );
}

export default Experience;
